<template>
    <div class="order-list">
        <table class="order-table">
            <thead>
                <tr>
                    <th>商品信息</th>
                    <th>单价/数量</th>
                    <th>支付方式</th>
                    <th>总价</th>
                    <th>收货人</th>
                    <th>处理时间</th>
                    <th>配送方式</th>
                    <th>订单状态</th>
                    <th>操作</th>
                </tr>
                <tr style="height: 10px"></tr>
            </thead>
            <tbody v-if="!list.length">
                <tr style="background: #fff">
                    <td colspan="9">
                        <No />
                    </td>
                </tr>
            </tbody>
            <tbody v-for="(item, index) in list" :key="index">
                <tr>
                    <td colspan="9">
                        <div class="order-head">
                            <div class="left">
                                <span class="sn">订单编号：{{ item.pay_sn }}</span>
                                <div v-if="item.store_id" style="margin-right: 10px">
                                    <el-tooltip placement="top">
                                        <el-tag type="success">门店订单</el-tag>
                                        <template slot="content">{{ item.shop_info.store_name }}</template>
                                    </el-tooltip>
                                </div>
                                <el-tag v-else type="primary" style="margin-right: 10px">总店订单</el-tag>
                                <el-tag v-if="item.order_type == 7" type="warning" style="margin-right: 10px">采购订单</el-tag>
                            </div>
                            <div class="right">
                                <!--<el-button v-show="item.state == 10" type="text" size="small"
                                    @click="changePrice(item)">改价</el-button>-->
                                <el-divider v-if="item.state == 10" direction="vertical"></el-divider>
                                <el-button v-show="item.state != 20 && item.state != 10 && item.state != 0" type="text"
                                    size="small" @click="logisticsQuery(item.id)">物流信息</el-button>
                                <el-divider v-if="item.state != 20 && item.state != 10 && item.state != 0"
                                    direction="vertical"></el-divider>
                                <el-button v-show="item.state != 20 && item.state != 10 && item.state != 0" type="text"
                                    size="small" @click="logisticsQuery(item.id)">修改物流</el-button>
                                <el-divider v-if="item.state != 20 && item.state != 10 && item.state != 0"
                                    direction="vertical"></el-divider>
                                <el-button type="text" size="small" @click="print(item)">打印订单</el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" size="small" @click="orderInfo(item.id)">订单详情</el-button>
                                <el-divider direction="vertical"></el-divider>
                                <el-button type="text" size="small" @click="setSystemMessage(item)">备注</el-button>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div class="goods-info">
                            <div class="item" v-for="goodsInfo in item.extend_order_goods" :key="goodsInfo.id">
                                <el-image :src="goodsInfo.goods_img"></el-image>
                                <div class="goods-title">
                                    <p>{{ goodsInfo.goods_title }}</p>
                                    <p v-if="goodsInfo.goods_spec">规格：{{ goodsInfo.goods_spec.title }}</p>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="goods-price">
                            <p v-for="goodsPrice in item.extend_order_goods" :key="goodsPrice.id">
                                <span>￥{{ goodsPrice.goods_price }}</span>
                                <span>x{{ goodsPrice.goods_num }}</span>
                            </p>
                        </div>
                    </td>
                    <td>
                        {{ item.payment_code == 10 ? '微信支付' : item.payment_code == 20 ? '支付宝支付' : item.payment_code == 30 ?
                            '华为支付' : item.payment_code == 40 ? '余额支付' : '会员卡支付' }}
                    </td>
                    <td>
                        <div class="freight_fee">
                            <p>
                                ￥{{ item.amount }}
                                <img v-show="item.goods_type == 2" src="@/assets/image/tuan.png" alt="" />
                                <el-tooltip placement="top" v-if="Number(item.revise_amount) > 0 ||
                                    Number(item.revise_freight_fee) > 0 ||
                                    Number(item.discount_reduction_amount) > 0 ||
                                    Number(item.pd_amount) > 0 ||
                                    Number(item.reduction_amount) > 0
                                    ">
                                    <div slot="content">
                                        <template v-if="Number(item.revise_amount) > 0">
                                            <span>改价后：￥{{ Number(item.revise_amount) }}</span>
                                            <br />
                                        </template>
                                        <template v-if="Number(item.revise_freight_fee) > 0">
                                            <span>运费改价后：￥{{ item.revise_freight_fee }}元</span>
                                            <br />
                                        </template>
                                    </div>
                                    <span>
                                        <i class="el-icon-warning-outline"></i>
                                    </span>
                                </el-tooltip>
                            </p>
                            <p v-if="item.freight_fee">（运费：{{ parseFloat(item.freight_fee) -
                                parseFloat(item.freight_reduction_amount) }}元）</p>
                        </div>
                    </td>
                    <td class="consignee">
                        <p>
                            {{ item.order_type == 3 ? item.extend_order_extend.reciver_infos.name
                                : item.order_type == 4 ? item.extend_order_extend.delivery_infos.name
                                    : item.user_name }}
                        </p>
                        <p>{{ item.order_type == 3 ? item.extend_order_extend.reciver_infos.phone
                            : item.order_type == 4 ?
                                item.extend_order_extend.delivery_infos.mobile : item.user_phone }}</p>
                    </td>
                    <td>
                        {{ getDateformat(item.create_time) }}
                    </td>
                    <td>
                        {{ order_type.find(v => v.val == item.order_type) ? order_type.find(v => v.val ==
                            item.order_type).label : '' }}
                    </td>
                    <td>
                        <span :style="{
                            color: item.state == 0 || item.state == 10 || item.state == 40 ? '#409EFF' : item.state == 20 ? '#f56c6c' : '#e6a23c',
                        }">
                            <span v-if="item.goods_type == 2" style="color: #e6a23c">
                                {{ item.group_state == 0 ? '待付款' : item.group_state == 1 ? '待成团' : item.group_state == 2 ?
                                    '拼团中' : item.group_state == 3 ? '拼团成功' : '拼团失败' }}({{
        item.group_men_num
    }}/{{ item.group_people_num }})
                            </span>
                            <span v-else>{{ item.state == 0 ? '已关闭' : item.state == 10 ? '待支付' : item.state == 20 ? '待发货' :
                                item.state == 30 ? '待收货' : '已完成' }}</span>
                        </span>
                    </td>
                    <td>
                        <el-link v-if="item.goods_type == 2 && item.group_state == 3 && item.state == 40" type="primary"
                            :underline="false">已完成</el-link>
                        <el-button v-if="item.goods_type == 2 && item.group_state == 3 && item.state == 20" type="primary"
                            size="small" @click.stop="orderSend(item)">发货</el-button>
                        <el-button v-if="item.state == 20 && item.goods_type != 2" type="primary" size="small"
                            @click.stop="orderSend(item)">发货</el-button>
                        <el-button v-if="item.state == 30" type="primary" size="small"
                            @click.stop="confirmReceipt(index)">确认收货</el-button>
                        <el-button v-if="item.state == 10" type="text" size="small"
                            @click.stop="cancelOrder(item)">取消订单</el-button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Paging :total="total" :page="content.page" :pageNum="content.rows" @updatePageNum="updateData"></Paging>
        <el-dialog title="发货" :visible.sync="showOrderSend" width="600px">
            <el-form label-width="90px" v-if="currentSelectOrderInfo.id">
                <el-form-item label="收货人：">{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.name
                }}</el-form-item>
                <el-form-item label="收货地址：">
                    {{ currentSelectOrderInfo.extend_order_extend.reciver_infos.combine_detail }}{{
                        currentSelectOrderInfo.extend_order_extend.reciver_infos.address }}
                </el-form-item>
                <el-form-item label="快递公司：">
                    <el-select v-model="express_id" filterable placeholder="请搜索">
                        <el-option v-for="item in logisticsList" :key="item.id" :label="item.company_name"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="快递单号：">
                    <el-input v-model="tracking_no"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showOrderSend = !1">取 消</el-button>
                <el-button type="primary" @click="confirmSend">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="物流信息" :visible.sync="showGistics" width="40%">
            <p style="font-size: 16px; font-weight: 500; margin-bottom: 15px">{{ logisticsInfo.company_name }}：{{
                logisticsInfo.nu }}</p>
            <el-timeline :reverse="reverse">
                <el-timeline-item v-for="(item, index) in logisticsInfo.data" :key="index" :timestamp="item.time">
                    {{ item.context }}
                </el-timeline-item>
            </el-timeline>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showGistics = !1">取 消</el-button>
                <el-button type="primary" @click="showGistics = !0">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="备注" :visible.sync="showMessage" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
                <el-form-item label="订单编号：">{{ currentSelectOrderInfo.pay_sn }}</el-form-item>
                <el-form-item label="总价：">{{ currentSelectOrderInfo.amount }}</el-form-item>
                <el-form-item label="收货人：">{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.name
                }}</el-form-item>
                <el-form-item label="备注：">
                    <el-input v-model="currentSelectOrderInfo.system_message" type="textarea" :rows="8"
                        placeholder="请输入备注"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMessage = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmSetSystemMessage">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="订单改价" :visible.sync="showChangePrice" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" class="order-change">
                <el-form-item label="订单编号：">{{ currentSelectOrderInfo.pay_sn }}</el-form-item>
                <el-form-item label="收货人：">{{ currentSelectOrderInfo.extend_order_extend.reciver_infos.name
                }}</el-form-item>
                <el-form-item label="订单状态：">{{ currentSelectOrderInfo.order_status }}</el-form-item>
                <el-table :data="currentSelectOrderInfo.extend_order_goods"
                    :header-cell-style="{ 'background-color': '#F8F9FA' }" center>
                    <el-table-column prop="date" label="商品信息" width="300">
                        <template slot-scope="scope">
                            <div class="change-goods-info">
                                <el-image :src="scope.row.goods_img"></el-image>
                                <div class="goods-title">
                                    <p>{{ scope.row.goods_title }}</p>
                                    <p v-if="scope.row.goods_spec">规格：{{ scope.row.goods_spec.title }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_num" label="单价/数量">
                        <template slot-scope="scope">
                            <p>{{ scope.row.goods_price }}</p>
                            <p>x{{ scope.row.goods_num }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="goods_pay_price" label="小计"></el-table-column>
                    <el-table-column prop="difference_price" label="改价后">
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.difference_price"></el-input>
                        </template>
                    </el-table-column>
                </el-table>
                <el-form-item label="运费价格：" class="freight-price">
                    <el-input v-model="revise_freight_fee"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showChangePrice = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmChangePrice">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="取消订单" :visible.sync="showCancelOrder" width="35%">
            <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
                <el-form-item label="取消原因：">
                    <el-select v-model="cancel_reason_id" filterable placeholder="请选择取消原因">
                        <el-option v-for="item in cancelList" :key="item.id" :label="item.title"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="取消说明：">
                    <el-input v-model="state_remark" type="textarea" :rows="8" placeholder="请输入取消说明"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showCancelOrder = !1">取 消</el-button>
                <el-button type="primary" @click="comfirmCancelOrder">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import fash from '@/assets/image/fash.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import { mapState } from 'vuex';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
export default {
    props: {
        content: {
            type: Object,
        },
    },
    data () {
        return {
            total: 0,
            list: [],
            showCancelOrder: !1,
            showGistics: !1,
            showChangePrice: !1,
            showMessage: !1,
            currentSelectOrderInfo: {},
            logisticsInfo: [], //物流信息
            showOrderSend: !1,
            order_id: '', //订单id
            express_id: '', //物流公司id
            tracking_no: '', //发货订单号
            cancel_reason_id: '', //取消原因id
            cancelInfo: [], //取消信息
            state_remark: '', //取消说明
            revise_freight_fee: '', //修改运费的价格
            order_type: [
                {
                    val: 1,
                    label: '到店付款',
                },
                {
                    val: 3,
                    label: '快递物流',
                },
                {
                    val: 4,
                    label: '商家自送',
                },
                {
                    val: 5,
                    label: '自提',
                },
                {
                    val: 7,
                    label: '快递物流',
                }
            ],
        };
    },
    components: {
        Paging: Paging,
    },
    computed: {
        ...mapState({
            logisticsList: state => state.order.logisticsList, //物流公司列表
            cancelList: state => state.order.cancelList,
        }),
    },
    watch: {
        showOrderSend (val) {
            if (val && !this.logisticsList.length) this.$store.dispatch('order/getLogistics', { rows: 10000 });
        },
        showCancelOrder (val) {
            if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
        },
    },
    created () {
        let utils = this.$store.state.utils;
        if (utils.is_record) {
            this.content.page = utils.page;
            this.content.rows = utils.rows;
            utils.is_record = !1;
        }
        this.getList();
    },
    methods: {
        confirmSend () {
            if (!this.tracking_no)
                return this.$message({
                    message: '请填写快递单号',
                    type: 'warning',
                });
            this.$axios
                .post(industryName + this.$api.order.setSend, {
                    id: this.currentSelectOrderInfo.id,
                    need_express: 1,
                    express_id: this.express_id,
                    tracking_no: this.tracking_no,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '发货成功',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                        this.showOrderSend = !1;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        updateData (val, status) {
            if (status == 0) {
                this.content.rows = val;
            } else {
                this.content.page = val;
            }
            this.getList();
        },
        getDateformat (val) {
            return getDateformat(val);
        },
        orderInfo (id) {
            this.$router.push({
                path: '/repair/goods/orderinfo',
                query: {
                    id: id,
                    page: this.content.page,
                    rows: this.content.rows,
                },
            });
        },
        //确认收货
        confirmReceipt (index) {
            let orderInfo = this.list[index].extend_order_goods;
            for (let i in orderInfo) {
                if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可确认收货！');
            }
            this.$confirm(`是否确认强制收货（谨慎使用！）`, '提示', {
                confirmButtonText: '确定',
                type: 'warning',
            }).then(() => {
                this.$axios.post(industryName + this.$api.order.confirmReceipt, { id: this.list[index].id }).then(res => {
                    if (res.code == 0) {
                        this.$message({
                            message: '订单收货成功',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            });
        },
        //打印
        print (row) {
            let routeData = this.$router.resolve({
                path: '/print',
                query: {
                    id: row.id,
                    industryName: industryName + '/'
                },
            });
            window.open(routeData.href, '_blank');
        },
        //取消订单
        cancelOrder (row) {
            this.currentSelectOrderInfo = row;
            this.state_remark = '';
            this.showCancelOrder = !0;
        },
        comfirmCancelOrder () {
            if (!this.cancel_reason_id)
                return this.$message({
                    message: '请选择取消原因',
                    type: 'warning',
                });
            if (!this.state_remark)
                return this.$message({
                    message: '请填写取消说明',
                    type: 'warning',
                });
            this.$axios
                .post(industryName + this.$api.order.cancel, {
                    id: this.currentSelectOrderInfo.id,
                    state_remark: this.state_remark,
                    cancel_reason_id: this.cancel_reason_id,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showCancelOrder = !1;
                        this.$message({
                            message: '订单已取消',
                            type: 'success',
                        });
                        this.getList();
                        this.$parent.$parent.getOrderNum();
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //查看物流信息
        logisticsQuery (id) {
            this.$axios.post(industryName + this.$api.order.logisticsQuery, { id: id }).then(res => {
                if (res.code == 0) {
                    this.showGistics = !0;
                    res.result.info.company_name = res.result.company_name;
                    this.logisticsInfo = res.result.info;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        //改价
        changePrice (row) {
            this.currentSelectOrderInfo = row;
            if (this.currentSelectOrderInfo.revise_freight_fee > 0) {
                this.revise_freight_fee = this.currentSelectOrderInfo.revise_freight_fee;
            } else {
                this.revise_freight_fee = this.currentSelectOrderInfo.freight_fee;
            }
            this.showChangePrice = !0;
        },
        comfirmChangePrice () {
            let revise_goods = [];
            let extend_order_goods = this.currentSelectOrderInfo.extend_order_goods;
            for (let i in extend_order_goods) {
                if (!extend_order_goods[i].difference_price)
                    return this.$message({
                        message: '请填写修改价格',
                        type: 'warning',
                    });
                if (extend_order_goods[i].difference_price <= 0) return this.$message.warning('修改价格不能小于等于0');
                let data = {};
                data.id = extend_order_goods[i].id;
                data.goods_pay_price = Number(extend_order_goods[i].goods_pay_price);
                data.difference_price = extend_order_goods[i].difference_price;
                revise_goods.push(data);
            }
            this.$axios
                .post(this.$api.order.changePrice, {
                    revise_goods: revise_goods,
                    revise_freight_fee: Number(this.revise_freight_fee),
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showChangePrice = !1;
                        this.getList();
                        this.$message({
                            message: '修改成功',
                            type: 'success',
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //设置备注信息
        setSystemMessage (row) {
            this.currentSelectOrderInfo = row;
            this.showMessage = !0;
        },
        comfirmSetSystemMessage () {
            this.$axios
                .post(industryName + this.$api.order.setSystemMessage, {
                    id: this.currentSelectOrderInfo.id,
                    system_message: this.currentSelectOrderInfo.system_message,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.showMessage = !1;
                        this.$message({
                            message: '备注成功',
                            type: 'success',
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        //发货
        orderSend (row) {
            let orderInfo = row.extend_order_goods;
            for (let i in orderInfo) {
                if (orderInfo[i].refund_handle_state == 0 && orderInfo[i].refund_id > 0) return this.$message.warning('该订单存在退款商品，不可发货！');
            }
            if (row.header_id && row.consignee_time != '0') {
                this.$confirm(`是否确认开始配送`, '提示', {
                    confirmButtonText: '确定',
                    type: 'warning',
                }).then(() => {
                    this.$axios.post(industryName + this.$api.retail.setDelivery, {
                        id: row.id
                    }).then(res => {
                        if (res.code == 0) {
                            this.getList();
                            this.$message.success("处理成功");
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                });
                return;
            }
            this.currentSelectOrderInfo = row;
            this.tracking_no = '';
            this.showOrderSend = !0;
        },
        getList () {
            let content = this.content;
            if (content.create_time.some(v => !v)) content.create_time = []
            let data = {
                order_type: content.order_type,
                page: content.page,
                rows: content.rows,
                create_time: content.create_time,
                group_sign: content.group_sign,
            };
            if (content.state_type == 'group_state_pay') {
                data.group_state_type = 'group_state_pay';
                data.state_type = 'all';
            } else {
                data.state_type = content.state_type;
            }
            if (content.payment_code != 'whole') {
                data.payment_code = content.payment_code;
            }
            if (content.feedback_state != 'whole') {
                data.feedback_state = content.feedback_state;
            }
            if (content.from != 'whole') {
                data.from = content.from;
            }
            if (content.keywords) {
                data.keywords_type = content.keywords_type;
                data.keywords = content.keywords;
            }
            if (content.room_id) {
                data.room_id = content.room_id;
                data.goods_id = content.goods_id;
            }
            if (content.header_id) {
                data.header_id = content.header_id;
            }
            if (this.$route.query.user_ids) data.user_ids = this.$route.query.user_ids;
            this.$axios.post(industryName + this.$api.order.list, data).then(res => {
                if (res.code == 0) {
                    let list = res.result.list;
                    for (let i in list) {
                        list[i].order_status = list[i].state == 0 ? '已关闭' : list[i].state == 10 ? '待支付' : list[i].state == 20 ? '待发货' : list[i].state == 30 ? '待收货' : '已完成';
                        list[i].revise_freight_fee = Number(list[i].revise_freight_fee);
                        list[i].revise_amount = Number(list[i].revise_amount);
                        if (list[i].extend_order_extend.reciver_infos.combine_detail) {
                            list[i].extend_order_extend.reciver_infos.combine_detail = list[i].extend_order_extend.reciver_infos.combine_detail.replace(/\s*/g, '');
                        }

                        if (list[i].from == 1) list[i].from_icon = wx;
                        if (list[i].from == 2) list[i].from_icon = ali;
                        if (list[i].from == 3) list[i].from_icon = fash;
                        if (list[i].from == 4) list[i].from_icon = douyin;
                        if (list[i].from == 5) list[i].from_icon = qq;
                        if (list[i].from == 6) list[i].from_icon = baidu;
                        let extend_order_goods = list[i].extend_order_goods;
                        for (let y in extend_order_goods) {
                            if (extend_order_goods[y].goods_spec) {
                                extend_order_goods[y].goods_spec = JSON.parse(extend_order_goods[y].goods_spec);
                            }
                            extend_order_goods[y].difference_price = extend_order_goods[y].goods_pay_price;
                        }

                        if (list[i].extend_order_extend.delivery_info) {
                            list[i].extend_order_extend.delivery_infos = JSON.parse(list[i].extend_order_extend.delivery_info);
                        }
                    }
                    this.list = list;
                    console.log(list)
                    this.total = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.order-table {
    width: 100%;
    font-size: 14px;

    tbody {
        border: 1px solid #ebeef5;
    }

    thead tr th {
        padding: 15px;
        text-align: center;
    }

    thead tr {
        background: #f8f9fa;

        th:first-child {
            width: 280px;
        }

        th:last-child {
            width: 250px;
        }
    }

    tbody tr:first-child {
        border-bottom: 1px solid #ebeef5;

        background: #f8f9fa;

        td {
            padding: 5px 20px;
        }
    }

    tbody tr:last-child {
        td {
            text-align: center;
        }

        td:first-child {
            width: 280px;
        }

        td:last-child {
            width: 250px;
        }
    }

    .goods-info {
        width: 300px;
        flex: none;
        padding-left: 20px;

        .item {
            height: 70px;
            display: flex;
            align-items: center;

            .el-image {
                width: 50px;
                height: 50px;
                margin-right: 10px;
                border-radius: 5px;
            }

            .goods-title {
                text-align: left;
                flex: 1;
                font-size: 13px;

                p:nth-child(1) {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }

                p:nth-child(2) {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    margin-top: 5px;
                }
            }
        }
    }

    .order-head {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;

            .sn {
                margin-right: 70px;
            }

            div {
                display: flex;
                align-items: center;
            }

            .el-button {
                cursor: auto;
            }
        }

        .right {
            width: 250px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .goods-price p {
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 70px;
        justify-content: center;

        span:nth-child(1) {
            margin-bottom: 5px;
        }
    }

    .freight_fee {
        p:nth-child(1) {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 19px;
                height: 20px;
                margin-left: 8px;
            }
        }

        i {
            font-size: 15px;
        }
    }

    .consignee p {
        &:nth-child(1) {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 16px;
                height: 16px;
                margin-left: 8px;
            }
        }
    }
}

.change-goods-info {
    display: flex;
    align-items: center;

    .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 5px;
    }
}

.freight-price {
    margin-top: 20px;

    .el-input {
        width: 100px;
    }
}
</style>
